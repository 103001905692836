import styled from 'styled-components';
import Img from "gatsby-image"

export const Container = styled.section`
    background: #FFF;
    border-bottom: 2px solid #E9ECEF;
    display: block;

    @media (max-width: 768px) {
        border: none;        
    }
`;

export const Title = styled.div`
    display: block;
    margin: 15vh 5vh 53px;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (max-width: 768px) {
        margin: 10vh auto 7vh;
    }

    h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        color: #173746;
    }

    span {
        box-shadow: 5px 0 0 0 #80F5E4 ;
        background: #80F5E4;
        display: inline;
        padding: 3px 0 !important;
        position: relative;
    }
`;

export const Companies = styled.div`
    display: flex;
    margin: 54px auto 0;
    align-items: center;
    justify-content: center;
    
    flex-direction: row;
    flex-wrap: wrap;
    
    @media (max-width: 768px) {
        flex-wrap: wrap;
        margin-bottom: 80px;
    }
`;

export const CompanyImg = styled(Img)`
    display: block;
    margin: auto 40px;
    width: 130px;
    
    & + div {
        margin-bottom: 70px;
    }

    @media (max-width: 768px) {
        margin: auto 15px 60px;
        
    }
`;
import React from 'react';

import { useStaticQuery, graphql } from "gatsby"

import {Container,
        Title,
        Companies,
        CompanyImg} 
        from './styles';

const Company = () => {

   const { allFile } = useStaticQuery(graphql`
    query {
        allFile(filter: {absolutePath: {regex: "\/src/images/companies/"}}) {
            nodes {
                childImageSharp {
                    fluid(fit: INSIDE, maxWidth: 130, maxHeight: 54) {
                        ...GatsbyImageSharpFluid originalName
                    }
                }
            }
        }
    }`)

    return (
        <> 
            <Container id="company">
                <Title>
                    <h1>
                        Nós oferecemos soluções para empresas&nbsp; 
                        <span>
                            líderes em mais de 3 estados.
                        </span> 
                    </h1>
                </Title>

                <Companies>
                    
                    { allFile.nodes.map(item => (
                        <CompanyImg
                            key={`image_${item.childImageSharp.fluid.originalName}`}
                            fluid={item.childImageSharp.fluid} 
                            alt="logo"
                        />
                        
                    )) }
                    
                </Companies>
            </Container>
        </>
    )
}

export default Company